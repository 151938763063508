import React from 'react'
import '../css/hero.css'
import logo from '../assets/images/logos/apex_APEX5.png'

export default function Hero() {
  return (
    <section className='hero'>

        <div className='container'>
            <div className='hero_container'>
                <div className='hero_content'>
                    <div>
                        <img src={logo} alt='logo apex baja ferries' />
                    </div>
                    <div>
                        <p>Acreditación para Pequeñas Empresas con Excelencia en Aprendizaje</p>
                    </div>
                    <div className='cta_hero'>                        
                        <a href={`${process.env.PUBLIC_URL}/descargas/convocatoria-web.pdf`} rel='noopener noreferrer' target="_blank">Convocatoria</a> 
                        <a href={`${process.env.PUBLIC_URL}/descargas/bases-de-participacion.pdf`} rel='noopener noreferrer' target="_blank">Bases</a> 
                        <a href={`${process.env.PUBLIC_URL}/descargas/guia-apex.pdf`} rel='noopener noreferrer' target="_blank">Guía</a>                         
                    </div>
                    
                </div>
               
            </div>
        </div>

    </section>
  )
}
