import React, {useState, useEffect} from 'react'
import {  useNavigate, Outlet, Link  } from 'react-router-dom';
import '../css/registro.css'
import logo from '../assets/images/logos/PAZ_Apex_Logotipo-01.png'
import Footer from '../components/Footer';

export default function Registro() {
    const [task, setTask] = useState({
        correo: '',
        contrasena:'',
        aviso_privacidad: false,
        fecha_alta: '',
    })
    const [alert, setAlert] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) =>{
        const { name, value, type, checked } = e.target;
        setTask((prevTask) => ({
            ...prevTask,
            [name]: type === 'checkbox' ? checked : value,
        }));
    }

    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0]; // Formato: YYYY-MM-DD
        setTask((prevTask) => ({ ...prevTask, fecha_alta: currentDate }));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("task: ", JSON.stringify(task)); 
        try {
            //const res = await fetch('http://192.168.100.72:3091/tasks',{
                const res = await fetch('https://apexlpz.bajaferries.com.mx/tasks', {
                //const res = await fetch('http://localhost:3091/tasks',{
                
                method:'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(task),                
            });


            // Verificar si la respuesta no es exitosa
            if (!res.ok) {
                // Intentar obtener el error devuelto por el servidor
                const errorData = await res.json();
                console.error('Error en el registro:', errorData.error);

                // Mostrar el mensaje de error al usuario (puedes mostrarlo en el DOM)
                setAlert(true);
                //alert(errorData.error); // Por ejemplo, usando un alert, o manejar con un estado
                return; // Detener la ejecución si hay un error
            }else{
                //Registro exitoso

                // Asegúrate de esperar el JSON parseado
                const data = await res.json(); 
                console.log("userId: ", data);            

                if (data) {
                    //localStorage.setItem('userId', data.id);  // Guarda el userId en localStorage
                    localStorage.setItem('userId', data);  // Guarda el userId en localStorage                    
                }

                setTask({
                    correo: '',              
                    contrasena: '',
                    fecha_alta: new Date().toISOString().split('T')[0],
                });

                console.log('Formulario enviado con éxito');
                navigate('/registro-completo');
            }           

        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }

    }

  return (
    <>
    <section className='registro'>
        <div className='container'>
            <div className='form-content'>
                <form onSubmit={handleSubmit}>
                <div className='login-box-logo'>
                        <img src={logo} alt='logo apex' class="logo"/>
                    </div>
                    <h2>Registro</h2>
                    <p>Ingresa correo y contraseña para crear una cuenta</p>

                    {alert && (
                        <>
                            <label className='alertaMensaje'>El correo electrónico ya ha sido registrado. Por favor, utiliza otro correo o inicia sesión si ya tienes una cuenta. </label> <br />
                        </>
                    )}
                    <div className='input-group'>
                        
                        
                        <label>Correo</label>
                        <input type='email' name='correo'  value={task.correo} onChange={handleChange} />
                    </div>
                    <div className='input-group'>
                        <label>Contraseña</label>
                        <input type='password' name='contrasena' value={task.contrasena} onChange={handleChange} />
                    </div>
                    <div>
                        <input type="checkbox" name="aviso_privacidad" checked={task.aviso_privacidad} onChange={handleChange}/>{' '}
                        Acepto haber leído el <Link to='/aviso-de-privacidad'>aviso de privacidad </Link> 
                    </div>
                    
                    <div className='input-group'>
                        <button  type='submit' disabled={!task.correo || !task.contrasena || !task.aviso_privacidad}>
                            Registrar 
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
    
    <Outlet />
    <Footer />
    </>
  )
}






















