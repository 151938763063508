import React, {useState, useEffect, useCallback} from 'react'
import '../css/dashboard.css'
import Navbar from '../components/Navbar'
import logo from '../assets/images/logos/apex_APEX5.png'
import {  useNavigate, Outlet, Link} from 'react-router-dom';
export default function Dashboard() {
   

    const navigate = useNavigate();

    // Declaramos el estado y los hooks antes de cualquier condición
    const [tasks, setTasks] = useState([]);

    const userId = localStorage.getItem('userId');  // Recuperar usuario_id desde localStorage

    const loadTasks = useCallback(async () => {
       // const response = await fetch(`http://192.168.100.72:3091/user_capacitacion/${userId}`);
       //const response = await fetch(`https://apexlpz.bajaferries.com.mx/user_capacitacion/${userId}`);
       const response = await fetch(`https://localhost:3091/user_capacitacion/${userId}`);
        const data = await response.json();
        console.log(data);
        setTasks(data);
    }, [userId]);

    useEffect(() => {
        if (userId) {
            loadTasks();
        }
    }, [userId, loadTasks]);  // useEffect se ejecuta solo si userId existe

    // Si no hay userId, mostramos un mensaje alternativo pero no afectamos la ejecución de los hooks
    
    if (!userId) {
        return <div>Usuario no encontrado. Por favor, inicia sesión.</div>;
    }

  return (
    <>
    <Navbar />

    <section className='dashboard'>
        <div className='sidebar'>
            <ul>
                <li><Link to="/dashboard"><i class="fi fi-rr-home"></i> Dashboard</Link></li>                
                <li><i class="fi fi-rr-power"></i> Cerrar sesión</li>               
            </ul>
             
            <ul>
                <li>Documentación</li>
                <li><a href={`${process.env.PUBLIC_URL}/descargas/convocatoria-web.pdf`} rel='noopener noreferrer' target="_blank"><i class="fi fi-rr-file-pdf"></i> Convocatoria</a> </li>    
                <li><a href={`${process.env.PUBLIC_URL}/descargas/bases-de-participacion.pdf`} rel='noopener noreferrer' target="_blank"><i class="fi fi-rr-file-pdf"></i> Bases</a> </li>    
                <li><a href={`${process.env.PUBLIC_URL}/descargas/guia-apex.pdf`} rel='noopener noreferrer' target="_blank"><i class="fi fi-rr-file-pdf"></i> Guía</a> </li>                  
            </ul>
            <ul>
                <li>Legal</li>
                <li><Link to="/aviso-de-privacidad"><i class="fi fi-rr-legal"></i> Aviso de privacidad</Link></li>                    
                <li><Link to="/terminos-y-condiciones-participacion"><i class="fi fi-rr-legal"></i>  Términos y condiciones de participación</Link></li>                      
                <li><Link to="/terminos-y-condiciones"><i class="fi fi-rr-legal"></i>  Términos y condiciones</Link></li>                                                     
            </ul>
            <ul>
                <li>¿Necesitas ayuda? </li>
                
                <li><a href="mailto:apex@bajaferries.com.mx"><i class="fi fi-rr-envelope"></i> Contactar</a></li>    
                
            </ul>
        </div>
        <div className='dashboard-space'>
            <div className=''>
            <div className='box_bienvenida'>
                    <div>
                        {/*<h2>Bienvenido a <strong>ApexLPZ</strong></h2>*/}
                        <h2>Bienvenido al panel de<br /> <strong>Administración</strong></h2>
                            <p>Aquí podrás verificar tu avance y completar tu postulación  </p>
                    </div>
                    <div className='box_bienvenida_img'>
                        <img src={logo} alt='logo apex blanco' />
                    </div>
               </div>
            </div>
            {tasks.evidencia_estatus ? (
                <div className='dashboard-content-capacitacion'>
                                   
                    <div className='dashboard-box-capacitacion noEdit' >
                        <label>Desarrollo Organizacional</label>
                        <label>{tasks.desarrollo_porcent_evidencia || '0'}%</label>
                        <label>{tasks.desarrollo_estatus || 'N/A'}</label>
                    </div>                                      
                    <div className='dashboard-box-capacitacion noEdit' >
                        <label>Sostenible</label>
                        <label>{tasks.sostenible_porcent_evidencia || '0'}%</label>
                        <label>{tasks.sostenible_estatus || 'N/A'}</label>
                    </div>
                    <div className='dashboard-box-capacitacion noEdit'>
                        <label>Nuevas Habilidades</label>
                        <label>{tasks.habilidades_porcent_evidencia || '0'}%</label>
                        <label>{tasks.habilidades_estatus || 'N/A'}</label>
                    </div>
                </div>
            ):(
            <div className='dashboard-content-capacitacion'>
                                   
                <div className='dashboard-box-capacitacion' onClick={() => navigate(`/capacitacion/${userId}/edit`)} >
                    <label>Desarrollo Organizacional</label>
                    <label>{tasks.desarrollo_porcent_evidencia || '0'}%</label>
                    <label>{tasks.desarrollo_estatus || 'N/A'}</label>
                </div>                                      
                <div className='dashboard-box-capacitacion' onClick={() => navigate(`/capacitacion/${userId}/edit`)} >
                    <label>Sostenible</label>
                    <label>{tasks.sostenible_porcent_evidencia || '0'}%</label>
                    <label>{tasks.sostenible_estatus || 'N/A'}</label>
                </div>
                <div className='dashboard-box-capacitacion' onClick={() => navigate(`/capacitacion/${userId}/edit`)} >
                    <label>Nuevas Habilidades</label>
                    <label>{tasks.habilidades_porcent_evidencia || '0'}%</label>
                    <label>{tasks.habilidades_estatus || 'N/A'}</label>
                </div>
            </div>
            )}
            
        </div>
    </section>

    <section>
       <div>
        
       </div>
    </section>
    
    <Outlet />
    
    </>
  )
}
